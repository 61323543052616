import hostessesVisio from '../constants/HostessesVisioArray';
import headerHostessesVisio from '../img/header-hotesses-visio.webp';
import { Helmet } from 'react-helmet-async';
import { Container, Image, Card, Row, Col, Spinner, Button } from "react-bootstrap";
import { useState } from 'react';
import sva from '../img/telrose-visio-cam-main.webp';

function Hostesses() {
  const [profilHostesse, setProfilHostesse] = useState({
    name: "",
    photo: "",
    description: ""
  });

  const [chatOpen, setChatOpen] = useState(false);

  function scroll(section) {
    window.scrollTo({
      top: document.getElementById(section).getBoundingClientRect().y + window.scrollY - document.getElementById("header").offsetHeight - 25
    })
  }

  function displayProfil(name, photo, description) {
    setProfilHostesse({
      photo: photo,
      name: name,
      description: description
    })
    document.getElementById('section-profil').style.display = "block";
    if (!chatOpen) {
      setChatOpen(true);
    }

    chatOpen ? document.getElementById("button-loading").style.display = "none" : document.getElementById("button-loading").style.display = "block";

    document.getElementById("iframe-container").style.height = "825px";
    setTimeout(() => { scroll("section-profil"); }, 1);
    setTimeout(() => {
      document.getElementById("iframe-chat").style.position = "static";
      document.getElementById("iframe-chat").style.opacity = "100";
      document.getElementById("button-loading").style.display = "none";
      document.getElementById("title-chat").style.display = "block";
    }, 3000);
  }

  return (
    <>
      <Helmet>
        <title>Hotesses de Téléphone Rose en Visio - Appelez le 0895680800</title>
        <meta name="description"
          content="Découvrez nos hôtesses de téléphone rose en visio : des conversations intimes et sensuelles pour éveiller vos fantasmes. Disponibles 24/7 pour vous satisfaire" />
        <link rel="canonical" href="https://visio-telrose.fr/hotesses" />
        {/* <link rel="preload" as="image" href="https://telrose-telephone.be/img/banner-home.webp" /> */}
      </Helmet>
      <section className="header pt-3 mb-4">
        <Container fluid='lg'>
          <Row>
            <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
              <div className='title-header-page'>
                <h1 className="mb-4 text-center text-lg-start fw-bold display-5">Découvrez Nos Hôtesses de Téléphone Rose en Visio</h1>
                <h2 className="fs-4 text-center text-lg-start fw-normal">Choisissez parmis l'une de nos hôtesses et échangez en direct</h2>
              </div>
            </Col>
            <Col xs={12} lg={6} className='p-0'>
              <div className='image-header-page text-center'>
                <Image width={660} height={440} src={headerHostessesVisio} fluid alt='photo hôtesse'/>
              </div>
            </Col>
          </Row>
        </Container >
      </section >
      <Container as={"section"} fluid="lg" className="container-hostesses text-center">
        <h3 className="title-red-hostesses fs-3 py-3 py-md-4 fw-bold">Échangez en direct avec l'une de nos hôtesses grâce à notre service de chat en visio</h3>
        <p>Pour échanger avec l'une de nos hôtesses, c'est très simple, il vous suffit de cliquer sur le profil de l'hôtesse qui vous intéresse et de composez le <a href="tel:0895680800" className='external-link fw-bold decoration-none'> 0 895 680 800</a> afin d'être mis en relation avec elle par téléphone. </p>
        <p>Vous pourrez ensuite échanger ensemble en visio depuis notre chat-visio plus bas sur cette page. </p>
        <Row as={"ul"} xs={2} sm={3} md={4} xl={5} className="mt-5 p-0">
          {hostessesVisio.map(hostesse => (
            <Col key={hostesse.id} as={"li"} className="mb-3">
              <Card onClick={() => displayProfil(hostesse.name, hostesse.photo, hostesse.description)} className="rounded-1 border-0 bg-black text-white" text="black">
                <Card.Img width={250} height={250} className="rounded-top-1 photo-hostesses" variant="top" src={`/img/photos_visio/photos_small/${hostesse.photo}.webp`} alt={hostesse.alt} />
                <Card.Body className='card-body rounded-bottom-1'>
                  <Card.Title className="m-0 fw-bold card-title-custom">{hostesse.name}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Container id="section-profil" as={"section"} style={{ display: "none" }} className="mt-5" fluid="lg">
        <div className='profil-body overflow-hidden rounded-1 text-center text-lg-start'>
          <Row as={"article"}>
            <Col lg={5}>
              <Image width={530} height={530} src={`/img/photos_visio/photos_large/${profilHostesse.photo}.webp`} className='object-fit-cover h-100' fluid />
            </Col>
            <Col>
              <div>
                <h4 className='my-4 fs-1'>{profilHostesse.name}</h4>
                <p className='my-2'>
                  {profilHostesse.description}
                </p>
              </div>
              <div className='text-center my-5 px-4'>
                <Image className='shadow' src={sva} fluid />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="my-3 text-center" fluid="lg">
        <Button id='button-loading' style={{ display: "none" }} className='loading-chat fw-bold mt-3' disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className='me-2'
          />
          Chargement du chat
        </Button>
        <h5 id="title-chat" className='mb-2 p-3 fs-4 fw-bold rounded-1'>CHAT-VISIO</h5>
        <div id='iframe-container'>
          <iframe id="iframe-chat" src="https://html5-chat.com/chat/50573/66d58b670ca26" allow="camera; fullscreen" width="100%" height="100%"></iframe>
        </div>
      </Container>
    </>
  )
}

export default Hostesses;