import { Navbar, Nav, Container, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import useScreenSize from '../hooks/useScreenSize';
import logo from '../img/logo-visio-nav.webp';
import sva from '../img/telrose-visio-cam-nav.webp';

function Header() {

  const { width } = useScreenSize();

  return (
    <header id="header" className="sticky-top w-100">
      <Navbar variant="dark" expand="lg" className="bg-black">
        <Container fluid='lg'>
          <div>
            <NavLink to={'/'} className="me-5"><Image className="logo" width={322} height={40} src={logo} fluid alt="logo visio-telrose.fr" /></NavLink>
            {width < 992 && width > 520 &&
              <a href="tel:0895680800"><Image width={251} height={28} className="shadow" src={sva} alt="numéro cliquable : 0 895 680 800" /></a>
            }
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink to={'/'} className="navLink text-white-50 text-decoration-none me-5 mb-1 mb-lg-0">Accueil</NavLink>
              <NavLink to={'forfaits'} className="navLink text-white-50 text-decoration-none me-5 mb-1 mb-lg-0">Forfaits</NavLink>
              <NavLink to={'hotesses'} className="navLink text-white-50 text-decoration-none me-5 mb-1 mb-lg-0">Hôtesses en Visio</NavLink>
            </Nav>

          </Navbar.Collapse>
          {width > 991 &&
            <a href="tel:0895680800"><Image width={251} height={28} className="shadow" src={sva} alt="numéro cliquable : 0 895 680 800" /></a>
          }
        </Container>
        {width < 520 &&
          <Container>
            <a href="tel:0895680800"><Image width={251} height={28} className="shadow" src={sva} alt="numéro cliquable : 0 895 680 800" /></a>
          </Container>
        }
      </Navbar>
    </header>
  )
}

export default Header;